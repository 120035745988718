//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    havePublishVersion: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    page: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isIncludeCodes: false,
      currentPage: {}
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.currentPage = JSON.parse(JSON.stringify(this.page));
      }
    }
  },

  computed: {
    ...mapGetters('project', ['projectById']),
    isPublic() {
      return this.currentPage?.ispublic || false;
    },
    modalTitle() {
      return this.$t(
        `Do you want to change the status of this page to ${this.isPublic ? 'private' : 'public'}?`
      );
    },
    isCatalogPage() {
      return this.currentPage?.template === 'CATALOG';
    },
    projectId() {
      return this.$route.params.projectId;
    },
    currentProject() {
      return this.projectById(this.projectId) || {};
    },
    isChangeProjectStatusWarning() {
      return !this.currentProject.is_public && !this.isPublic;
    },
    canChangeStatus() {
      return this.havePublishVersion;
    }
  },

  methods: {
    handleChangeStatus() {
      const isChangePublic = !this.isPublic;
      let includes = [];
      if (this.isCatalogPage) {
        includes = this.isIncludeCodes ? ['code'] : [];
      }
      if (this.isChangeProjectStatusWarning) {
        includes = includes.concat(['project']);
      }
      this.$emit('ok', {
        id: this.currentPage.id,
        payload: { isPublic: isChangePublic, includes }
      });
    },
    handleOpenIframeReview() {
      this.$emit('onOpenIframeReview', this.currentPage.id, true);
    }
  }
};
